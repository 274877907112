.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.banner-hide {
  display: none;
}

@media only screen and (min-width: 768px) {
  .banner-hide {
    display: block;
  }
}

.input-label {
  font-size: 18px;
  font-family: "Inter";
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.7px;
  color: #000000;
  transition: 0.3s;
}

.input-block {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 8px;
  border: 1px solid #FF6827;
  border-radius: 4px;
  margin-bottom: 20px;
  transition: 0.3s;

}

.input-block input {
  outline: 0;
  border: 0;
  color: #FF6827;
  padding: 4px 0 0;
  font-size: 14px;
  font-family: "Inter";

  &::placeholder {
    color: #FF6827;
    opacity: 1;
  }
}

.input-block select {
  outline: 0;
  border: 0;
  color: #FF6827;
  padding: 4px 0 0;
  font-size: 14px;
  font-family: "Inter";

  &::placeholder {
    color: #FF6827;
    opacity: 1;
  }
}

.input-block &:focus-within {
  border-color: #FF6827;

  .input-label {
    color: rgba(#FF6827, 0.8);
  }
}

th {
  font-weight: 600;
  border: solid 2px #EEEEEE!important;
}

table {
  /* width: 1200px!important; */
  font-size: 16px;
  border: solid 2px #EEEEEE!important;
}

.input-modal {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 8px;
  border-radius: 4px;
  margin-bottom: 20px;
  transition: 0.3s;
}

.input-modal input {
  outline: 0;
  border: 0;
  color: #424242;
  padding: 4px 0 0;
  font-size: 14px;
  font-family: "Inter";

  &::placeholder {
    color: #424242;
    opacity: 1;
  }
}

.input-modal select {
  outline: 0;
  border: 0;
  color: #424242;
  padding: 4px 0 0;
  font-size: 14px;
  font-family: "Inter";
  background-color: #E0E0E0;

  &::placeholder {
    color: #424242;
    opacity: 1;
  }
}

.input-label-modal {
  font-size: 12px;
  font-family: "Inter";
  font-weight: 500;
  line-height: 20px;
  color: #000000;
}

.sidebar-font {
  font-size: 18px;
}

.h2h {
  font-size: 20px;
  font-family: Inter;
  font-weight: 400;
  line-height: 24px;
  color: #FF6827;
  margin-bottom: 0;
}

.welcome {
  background-color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  color: #757575;
  border-top: solid 1px #757575;
  border-bottom: solid 1px #757575;
}

.list-header {
  border-bottom: solid 1px #b0b0b0;
}

.pagination {
  margin: 0;
}

.edit-modal {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 8px;
  border-radius: 4px;
  margin-bottom: 20px;
  transition: 0.3s;
}

.edit-modal input {
  outline: 0;
  border: 0;
  color: #424242;
  padding: 4px 0 0;
  font-size: 14px;
  font-family: "Inter";
  border: solid 1px #424242;

  &::placeholder {
    color: #424242;
    opacity: 1;
  }
}

.edit-modal select {
  outline: 0;
  border: 0;
  color: #424242;
  padding: 4px 0 0;
  font-size: 14px;
  font-family: "Inter";
  border: solid 1px #424242;

  &::placeholder {
    color: #424242;
    opacity: 1;
  }
}

.edit-label-modal {
  font-size: 12px;
  font-family: "Inter";
  font-weight: 500;
  line-height: 20px;
  color: #000000;
}

.active {
  font-weight: bolder;
}

.notification {
  border: 2px solid #1AC62B;
  border-radius: 7px;
  box-shadow: 0px 0px 2px 2px #a8a6a6;
  background-color: #f4fcf4;
}

.notiTitle {
  color: green;
  font-size: 16px;
  font-weight: 600;
}

.notiTitle i {
  border-radius: 50%;
  color: green;
}

.notiContent {
  font-size: 14px;
}